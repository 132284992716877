String.prototype.beszur = function (mit, pos) {
    return this.substring(0, pos).concat(mit) + this.slice(pos);
};

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.extraTrim = function () {
    var str = this.replace(/(\r\n|\n|\r)/gm, ' ');

    str = str.replace(/\s+/g, ' ');

    return str.replace(/^\s+|\s+$/g, '');
};

String.prototype.trim = function () {
    return this.replace(/^\s+|\s+$/g, '');
};

String.prototype.ltrim = function () {
    return this.replace(/^\s+/g, '');
};

String.prototype.rtrim = function () {
    return this.replace(/\s+$/g, '');
};

Array.prototype.inTomb = function (elem) {
    var i;
    for (i = 0; i < this.length; i++) {
        if (this[i] === elem) {
            return true;
        }
    }
    return false;
};
